// Basic libraries
import React, { Component } from "react"
import PropTypes, { arrayOf, elementType } from "prop-types"
import { navigate, Link } from "gatsby"

// Styling
import { CustomTableWrapper } from "./CustomTableStyling"

import { useTeacherStore } from "../../../store"
import { UI_EVENT_NAMES } from "../../const_values"
import { ChangeFilterActivity } from "../../services/helpers"

import { downloadExcel } from "react-export-table-to-excel"

const isBrowser = typeof window !== "undefined"
const { TEACHER_DASHBOARD } = UI_EVENT_NAMES

class CustomTableDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: this.props.rows,
      originRows: [],
      activeFilters: {},
      filterClasses: {},
      activeSortButtons: {},
      sortButtonClasses: {},
      sortButtonArrows: {},
      clearButtonActivated: {},
      inputValues: {},
      trafficLightSwitch: {},
      trafficLightFields: [],
    }
  }

  componentWillMount() {
    this.initializeSortButtons()
    this.initializeFilterClasses()
    this.initializeTrafficLights()
    this.setCorrectOrder()
    this.trafficLightDisplay()
  }

  initializeFilterClasses() {
    const columns = this.props.columnOrder
    const { filterClasses, clearButtonActivated } = this.state

    columns.forEach((column) => {
      filterClasses[column] = "searchBar"
      clearButtonActivated[column] = false
    })

    this.setState({
      filterClasses: filterClasses,
      clearButtonActivated: clearButtonActivated,
    })
  }

  initializeTrafficLights() {
    const { trafficLightIdx, hasTrafficLight } = this.props
    const { trafficLightSwitch } = this.state

    if (hasTrafficLight) {
      trafficLightIdx.forEach((idx) => {
        trafficLightSwitch[idx] = {
          red: true,
          yellow: true,
          green: true,
        }
      })

      this.setState({
        trafficLightSwitch: trafficLightSwitch,
      })
    }
  }

  initializeSortButtons() {
    const columns = this.props.columnOrder
    const { activeSortButtons, sortButtonClasses, sortButtonArrows } =
      this.state

    columns.forEach((column) => {
      activeSortButtons[column] = false
      sortButtonClasses[column] = "inactiveButton"
      sortButtonArrows[column] = " ▲ "
    })

    this.setState({
      activeSortButtons: activeSortButtons,
      sortButtonClasses: sortButtonClasses,
      sortButtonArrows: sortButtonArrows,
    })
  }

  setCorrectOrder() {
    const columnOrder = this.props.columnOrder
    const { rows } = this.state
    const orderedRows = []

    rows.forEach((row) => {
      let orderedRow = {}
      columnOrder.forEach((column) => {
        orderedRow[column] = row[column]
      })
      orderedRows.push(orderedRow)
    })

    this.setState(
      {
        rows: orderedRows,
        originRows: orderedRows,
      },
      () => this.forceUpdate()
    )
  }

  renderTableHeader() {
    const header = this.props.header
    const keys = this.props.columnOrder
    const hasTrafficLight = this.props.hasTrafficLight
    const { activeSortButtons, sortButtonClasses, sortButtonArrows } =
      this.state
    return header.map((entry) => {
      const index = header.indexOf(entry)
      const key = keys[index]
      return (
        <th>
          <button
            className={sortButtonClasses[key]}
            onClick={(e) => {
              if (!hasTrafficLight) {
                Object.keys(activeSortButtons).forEach((k) => {
                  activeSortButtons[k] = false
                })
                activeSortButtons[key] = true

                const currentArrow = sortButtonArrows[key]
                sortButtonArrows[key] = currentArrow === " ▲ " ? " ▼ " : " ▲ "
                Object.keys(activeSortButtons).forEach((k) => {
                  sortButtonClasses[k] = activeSortButtons[k]
                    ? "activeButton"
                    : "inactiveButton"
                })
                this.sortColumn(key, sortButtonArrows[key])
                this.setState(
                  {
                    activeSortButtons: activeSortButtons,
                    sortButtonClasses: sortButtonClasses,
                    sortButtonArrows: sortButtonArrows,
                  },
                  () => {
                    this.forceUpdate()
                  }
                )
              }
            }}
          >
            <span style={{ color: "rgba(255, 255, 255, 1)" }}>{entry}</span>
            {hasTrafficLight ? "" : sortButtonArrows[key]}
          </button>
        </th>
      )
    })
  }

  sortColumn = (key, arrow) => {
    const { rows } = this.state
    const sortDirection = arrow === " ▲ " ? 1 : -1

    const sortedRows = []
      .concat(rows)
      .sort((a, b) =>
        String(a[key]).toLowerCase() > String(b[key]).toLowerCase()
          ? sortDirection
          : -sortDirection
      )

    this.setState({ rows: sortedRows }, () => {
      this.forceUpdate()
      this.trafficLightDisplay()
    })
  }

  setActiveFilter(input, index) {
    const { activeFilters, filterClasses } = this.state

    if (input) {
      activeFilters[index] = input
      filterClasses[index] = "filteredSearchBar"
    } else {
      delete activeFilters[index]
      filterClasses[index] = "searchBar"
    }
  }

  filterTable = (e, entry) => {
    const header = this.props.header
    const { originRows, activeFilters, clearButtonActivated } = this.state
    const input = e.toLowerCase()
    const index = this.props.columnOrder[header.indexOf(entry)]
    this.setActiveFilter(input, index)

    let filteredRows = []
    // Wenn Filter gesetzt ...
    if (Object.keys(activeFilters).length > 0) {
      // s. "bobbyhadz.com/blog/react-deep-clone-object"
      let tempRows = JSON.parse(JSON.stringify(originRows))
      Object.keys(activeFilters).forEach((key) => {
        let index = tempRows.length - 1
        while (index >= 0) {
          if (
            !String(tempRows[index][key])
              .toLowerCase()
              .includes(activeFilters[key])
          ) {
            tempRows.splice(index, 1)
          }
          index -= 1
        }
      })
      filteredRows = tempRows
    } else {
      // Wenn gar kein Filter vorhanden ...
      filteredRows = originRows
    }

    this.setState({ activeFilters: activeFilters, rows: filteredRows }, () => {
      this.forceUpdate()
    })
  }

  renderFilterRow() {
    const header = this.props.header
    const { filterClasses, clearButtonActivated, inputValues } = this.state
    return header.map((entry) => {
      const index = this.props.columnOrder[header.indexOf(entry)]
      return (
        <td className="firstRow">
          <div className={filterClasses[index]}>
            <input
              type="text"
              placeholder="Filter ..."
              onChange={(e) => {
                if (isBrowser) {
                  try {
                    const filterChangeActivity = new ChangeFilterActivity(
                      window.location.pathname,
                      TEACHER_DASHBOARD.FILTER_CHANGED,
                      useTeacherStore.getState().teacher.id,
                      entry,
                      e.target.value
                    )

                    filterChangeActivity.writeToDB()
                  } catch (error) {
                    console.error("failed to log filter change: ", error)
                  }
                }

                const inputValue = e.target.value
                inputValues[index] = inputValue
                clearButtonActivated[index] = false
                this.filterTable(inputValue, entry)
                this.setState(
                  {
                    inputValues: inputValues,
                    clearButtonActivated: clearButtonActivated,
                  },
                  () => {
                    this.forceUpdate()
                  }
                )
              }}
              value={inputValues[index]}
            />
            <button
              onClick={() => {
                clearButtonActivated[index] = true
                inputValues[index] = ""
                this.filterTable(inputValues[index], entry)
                this.setState(
                  {
                    clearButtonActivated: clearButtonActivated,
                    inputValues: inputValues,
                  },
                  () => {
                    this.forceUpdate()
                  }
                )
              }}
            >
              x
            </button>
          </div>
        </td>
      )
    })
  }

  renderTableData() {
    const { trafficLightFields } = this.state
    const { hasTrafficLight, classId, studentsData } = this.props
    return this.state.rows.map((student, index) => {
      const arrStudent = Object.keys(student).map((key) => [
        Number(key),
        student[key],
      ])

      return (
        <tr>
          {arrStudent.map((entry, idx) => {
            if (hasTrafficLight && idx > 0 && idx < 7) {
              return (
                <td
                  onClick={() => {
                    let studentId = ""
                    let studentName = ""
                    studentsData.forEach((d) => {
                      if (
                        d["name"] == student["name"] ||
                        d["alias"] == student["name"]
                      ) {
                        studentId = d["id"]
                        studentName = d["name"]
                      }
                    })

                    if (studentName) {
                      navigate(
                        `/teacher/dashboard/diagrams?classId=${classId}&studentId=${studentId}`
                      )
                    }
                  }}
                >
                  <button
                    style={{
                      backgroundColor: trafficLightFields[index][idx - 1],
                      margin: "0 15px 0 0",
                    }}
                  />
                  {entry[1]}
                </td>
              )
            } else {
              return (
                <td
                  onClick={() => {
                    let studentId = ""
                    let studentName = ""
                    studentsData.forEach((d) => {
                      if (
                        d["name"] == student["name"] ||
                        d["alias"] == student["name"]
                      ) {
                        studentId = d["id"]
                        studentName = d["name"]
                      }
                    })
                    if (studentName) {
                      navigate(
                        `/teacher/dashboard/diagrams?classId=${classId}&studentId=${studentId}`
                      )
                    }
                  }}
                >
                  {entry[1]}
                </td>
              )
            }
          })}
        </tr>
      )
    })
  }

  handleDownloadExcel = () => {
    downloadExcel({
      fileName: "students-table-data",
      sheet: "data",
      tablePayload: {
        header: this.props.header,
        body: this.state.rows,
      },
    })
  }

  filterByTrafficLight = () => {
    const { originRows, trafficLightFields, trafficLightSwitch } = this.state
    let tempRows = JSON.parse(JSON.stringify(originRows))
    let filteredRows = tempRows
    Object.keys(trafficLightSwitch).forEach((key) => {
      const trafficLightColumn = trafficLightSwitch[key]
      const colorArray = ["red", "yellow", "green"]
      colorArray.forEach((col) => {
        const active = trafficLightColumn[col]
        // Zeile wegfiltern!
        if (!active) {
          originRows.forEach((row, idx) => {
            if (trafficLightFields[idx][Number(key) - 1] == col) {
              delete tempRows[idx]
            }
          })
          filteredRows = tempRows
        }
      })
    })
    this.setState({ rows: filteredRows }, () => {
      this.forceUpdate()
    })
  }

  trafficLightClicked = (trafficLightIdx, lightColor) => {
    const { trafficLightSwitch } = this.state
    trafficLightSwitch[trafficLightIdx][lightColor] =
      !trafficLightSwitch[trafficLightIdx][lightColor]
    this.setState(
      {
        trafficLightSwitch: trafficLightSwitch,
      },
      () => {
        this.filterByTrafficLight()
      }
    )
  }

  // Später anwenden, um nach student zu filtern (aktuell noch Bug!)
  renderStudentFilter(idx) {
    if (idx == 0) {
      const header = this.props.header
      const { filterClasses, clearButtonActivated, inputValues } = this.state
      const entry = header[idx]
      const index = this.props.columnOrder[header.indexOf(entry)]
      return (
        <div className={filterClasses[index]}>
          <input
            type="text"
            placeholder="Filter ..."
            onChange={(e) => {
              const inputValue = e.target.value
              inputValues[index] = inputValue
              clearButtonActivated[index] = false
              this.filterTable(inputValue, entry)
              this.setState(
                {
                  inputValues: inputValues,
                  clearButtonActivated: clearButtonActivated,
                },
                () => {
                  this.forceUpdate()
                }
              )
            }}
            value={inputValues[index]}
          />
          <button
            onClick={() => {
              clearButtonActivated[index] = true
              inputValues[index] = ""
              this.filterTable(inputValues[index], entry)
              this.setState(
                {
                  clearButtonActivated: clearButtonActivated,
                  inputValues: inputValues,
                },
                () => {
                  this.forceUpdate()
                }
              )
            }}
          >
            x
          </button>
        </div>
      )
    }
  }

  renderTrafficLight() {
    const header = this.props.header
    const { trafficLightIdx } = this.props
    const { trafficLightSwitch } = this.state
    return header.map((column, idx) => {
      return (
        <td className="firstRow">
          {trafficLightIdx.includes(idx) ? (
            <div className="trafficLight">
              <button
                style={{
                  backgroundColor: trafficLightSwitch[idx]["red"]
                    ? "red"
                    : "grey",
                  color: trafficLightSwitch[idx]["red"] ? "red" : "grey",
                  boxShadow: "0 2px 8px 0",
                }}
                onClick={() => {
                  this.trafficLightClicked(idx, "red")
                }}
              />
              <button
                style={{
                  backgroundColor: trafficLightSwitch[idx]["yellow"]
                    ? "yellow"
                    : "grey",
                  color: trafficLightSwitch[idx]["yellow"] ? "yellow" : "grey",
                  boxShadow: "0 2px 8px 0",
                }}
                onClick={() => {
                  this.trafficLightClicked(idx, "yellow")
                }}
              />
              <button
                style={{
                  backgroundColor: trafficLightSwitch[idx]["green"]
                    ? "green"
                    : "grey",
                  color: trafficLightSwitch[idx]["green"] ? "green" : "grey",
                  boxShadow: "0 2px 8px 0",
                }}
                onClick={() => {
                  this.trafficLightClicked(idx, "green")
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </td>
      )
    })
  }

  trafficLightDisplay = () => {
    const { rows } = this.state
    const categoryCluster = [[], [], [], [], [], []]
    const cleanedRows = []
    const trafficSection = {}
    const trafficLightFields = []

    rows.forEach((elem) => {
      const durationRaw = elem["duration"]
      const duration = durationRaw
        ? parseInt(durationRaw.split(":")[1]) +
          60 * parseInt(durationRaw.split(":")[0])
        : 0
      const durationFbRaw = elem["durationFb"]
      const durationFb = durationFbRaw
        ? parseInt(durationFbRaw.split(":")[1]) +
          60 * parseInt(durationFbRaw.split(":")[0])
        : 0

      const durationTotal = duration + durationFb

      const numberOfWords = elem["numberOfWords"]

      const averageAttemptsRaw = elem["averageAttempts"]
      const averageAttempts = averageAttemptsRaw
        ? parseFloat(averageAttemptsRaw)
        : 3

      const correctWordRatioRaw = elem["correctWordRatio"]
      const correctWordRatio = correctWordRatioRaw
        ? parseFloat(correctWordRatioRaw.split("%")[0]) / 100
        : 0.0

      cleanedRows.push({
        duration: duration,
        durationFb: durationFb,
        totalTime: durationTotal,
        numberOfWords: numberOfWords,
        averageAttempts: averageAttempts,
        correctWordRatio: correctWordRatio,
      })

      categoryCluster[0].push(duration)
      categoryCluster[1].push(durationFb)
      categoryCluster[2].push(durationTotal)
      categoryCluster[3].push(numberOfWords)
      categoryCluster[4].push(averageAttempts)
      categoryCluster[5].push(correctWordRatio)
    })

    trafficSection["duration"] = [true, Math.max(...categoryCluster[0]) / 3]
    trafficSection["durationFb"] = [true, Math.max(...categoryCluster[1]) / 3]
    trafficSection["totalTime"] = [true, Math.max(...categoryCluster[2]) / 3]
    trafficSection["numberOfWords"] = [
      true,
      Math.max(...categoryCluster[3]) / 3,
    ]
    trafficSection["correctWordRatio"] = [true, 1 / 3]
    trafficSection["averageAttempts"] = [false, 1.375]

    cleanedRows.forEach((row) => {
      const trafficLightRow = []
      Object.keys(trafficSection).forEach((key, idx) => {
        const theMoreTheBetter = trafficSection[key][0]
        const threshold = trafficSection[key][1]
        trafficLightRow[idx] = "yellow"
        if (row[key] <= threshold) {
          trafficLightRow[idx] = theMoreTheBetter ? "red" : "green"
        } else if (row[key] >= 2 * threshold) {
          trafficLightRow[idx] = theMoreTheBetter ? "green" : "red"
        }
      })
      trafficLightFields.push(trafficLightRow)
    })
    this.setState({ trafficLightFields: trafficLightFields }, () => {
      this.forceUpdate()
    })
  }

  render() {
    const { hasTrafficLight } = this.props
    return (
      <CustomTableWrapper>
        <button className="downloadButton" onClick={this.handleDownloadExcel}>
          Excel
        </button>
        <table className="students">
          <tbody>
            <tr className="tableHeader">{this.renderTableHeader()}</tr>
            {hasTrafficLight ? (
              <tr className="tableHeader">{this.renderTrafficLight()}</tr>
            ) : (
              <tr className="tableHeader">{this.renderFilterRow()}</tr>
            )}
            {this.renderTableData()}
          </tbody>
        </table>
      </CustomTableWrapper>
    )
  }
}

CustomTableDisplay.propTypes = {}

export default CustomTableDisplay
