// Styling libraries
import styled from "styled-components"
import { theme } from "../../styles/theme"

// Layout
export const CustomTableWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    height: 500px;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.phone}) and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletPortrait}) {
    height: 450px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    height: 450px;
  }

  overflow: scroll;

  .downloadButton {
    width: 74px;
    float: right;
    height: 32px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 2px 6px 0;
    margin: 20px;
    color: white;
    background-color: green;
  }

  .students {
    text-align: center;
    border-collapse: collapse;
    border: 2px solid ${({ theme }) => theme.colors.primary.shade};
    width: 100%;
    box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.primary.main};

    & :not(.tableHeader) td {
      text-align: start;
    }
  }

  .clearFilters button {
    position: sticky;
    padding: 5px;
    margin: 5px;
    border: none;
    color: white;
    background-color: ${({ theme }) => theme.colors.cta.shade};
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: scroll;
    outline: none;
    box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.cta.main};
    z-index: 1;
  }
  /* Tableheader und Filter-Reihe stick */
  .students tr:nth-child(1) {
    position: sticky;
    z-index: 2;
    top: -1px;
  }
  .students tr:nth-child(2) {
    position: sticky;
    z-index: 2;
    top: 49px;

    .trafficLight {
      background-color: black;
      width: 109px;
      border: none;
      border-radius: 20px;
      box-shadow: 0 2px 6px 0;
      margin: auto;

      button {
        height: 30px;
        width: 30px;
        border: none;
        border-radius: 1000px;
        margin: 3px 3px 3px 3px;
        padding-bottom: 2px;
      }
    }
  }

  /* erste Spalte sticky */
  .students tr td:first-child,
  .students tr th:first-child {
    position: sticky;
    z-index: 1;
    left: -2px;
  }

  .students td,
  students th {
    border: 2px solid ${({ theme }) => theme.colors.primary.shade};
    padding: 8px;
  }

  .students tr:nth-child(even),
  .students tr:nth-child(even) td:first-child {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
  .students tr:nth-child(odd),
  .students tr:nth-child(odd) td:first-child {
    background-color: ${({ theme }) => theme.colors.primary.shade};
  }

  .students tr:not(.tableHeader):hover,
  .students tr:not(.tableHeader):hover td:first-child {
    background-color: ${({ theme }) => theme.colors.cta.shade};
    transition: all 0.4s ease-in-out;
    box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.cta.main};
    cursor: pointer;
  }

  .students th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.cta.shade};
    box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.cta.main};
    white-space: nowrap;
  }

  .students th button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: white;
  }

  .students th .inactiveButton {
    color: rgba(255, 255, 255, 0.15);
  }

  .students th .activeButton {
    color: rgba(255, 255, 255, 1);
  }

  .students td .searchBar,
  .students td .filteredSearchBar {
    margin-bottom: 3px;
    padding: 3px;
    border: none;
    border-radius: 15px;
    display: flex;
  }

  .students td .searchBar {
    box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.primary.shade};
    background-color: white;
  }
  .students td .filteredSearchBar {
    box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.cta.main};
    background-color: ${({ theme }) => theme.colors.cta.main};
    color: white;

    button {
      color: white;
      background-color: ${({ theme }) => theme.colors.cta.shade};
      box-shadow: 0 4px 15px 0 ${({ theme }) => theme.colors.cta.main};
    }
  }

  .students td button {
    padding: 0px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: none;
    color: ${({ theme }) => theme.colors.primary.shade};
    background-color: transparent;
  }

  .students td input {
    background-color: transparent;
    border: none;
  }

  input:focus {
    border: none;
    outline: none;
  }
`
// Layout-end
