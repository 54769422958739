// Basic libraries
import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { sum } from "mathjs"
import { isMobileSafari } from "react-device-detect"

// Used components
import Navigation from "../../../components/navigation"
import Content from "../../../components/content"
import Seo from "../../../components/seo"
import DashboardTabs from "../../../components/DashboardTabs"

// Images & Styling
import { BackButton } from "../../../styles/buttons"
import { TeacherDashboardLayout } from "../../../styles/common"
import ArrowIcon from "../../../lib/icons/Arrow"

// Fetching data
import { useGraphqlClientStore, useTeacherStore } from "../../../../store"
// import graphqlClient from "../../../services/graphql-client"
import { gql } from "graphql-request"
import { isJWTExpired, TeacherActivity } from "../../../services/helpers"

// import { UI_EVENT_NAMES } from "../../../const_values"

// const { ALL } = UI_EVENT_NAMES

// Browser check
const isBrowser = typeof window !== "undefined"

// Token Access
const jwt = {
  jwtRequ: isBrowser ? window.localStorage.getItem("jwt_teacher") : null,
  checkPermission() {
    if ((!this.jwtRequ || isJWTExpired(this.jwtRequ)) && isBrowser)
      navigate("./")
    else if (this.jwtRequ)
      // graphqlClient.setHeader("authorization", `Bearer ${this.jwtRequ}`)
      useGraphqlClientStore.getState().setClientAuthHeader(this.jwtRequ)
  },
}

// GraphQL-Queries
const CLASS_BY_ID_QUERY = gql`
  query ($id: UUID!) {
    classById(id: $id) {
      id
      name
      studentsByClass {
        nodes {
          id
          name
          alias
          mistakePrognosis
          inputSessionsByStudent {
            nodes {
              word
              id
              correct
              attempt
              input
              durationMs
              durationMsFb
              startTime
            }
          }
        }
      }
    }
  }
`

const MISTAKE_CATEGORY_QUERY = gql`
  query {
    allFeedbacks {
      nodes {
        fehlerkategorie
        regel
        dashboardKategorie
      }
    }
  }
`

// component
class TeacherDashboardPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      window: {
        width: 0,
        height: 0,
      },
      urlPath: isBrowser ? window.location.pathname : null,
      class: {
        id: isBrowser
          ? new URLSearchParams(window.location.search).get("classId")
          : null,
        name: "",
        students: [],
      },
      mistakes: [],
      teacherDashboardData: [],
      teacherDashboardOrder: [],
      studentsWritingsData: [],
      studentsWritingsOrder: [],
    }
    this.handleResize = this.handleResize.bind(this)
    //this.logPageLeaving = this.logPageLeaving.bind(this)
    //this.finishPageLoading = this.finishPageLoading.bind(this)
  }
  // Fehlerschwerpunkt: 3 größten Zahlen in mistakeprognosis --> auf Fehlerkategorie mappen...

  handleResize = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  setData = async () => {
    const { graphqlClient } = useGraphqlClientStore.getState()
    const classId = this.state.class.id
    const classData = await graphqlClient.request(CLASS_BY_ID_QUERY, {
      id: classId,
    })
    console.log("class from query:", classData)
    const className = classData.classById.name
    const studentsData = classData.classById.studentsByClass.nodes

    const allMistakeData = await graphqlClient.request(MISTAKE_CATEGORY_QUERY)
    let mistakeData = allMistakeData.allFeedbacks.nodes
    const correctMistakeData = mistakeData.filter(
      (obj) => obj.regel.length === 8
    )
    const wrongMistakeData = mistakeData.filter((obj) => obj.regel.length === 7)

    wrongMistakeData.forEach((obj) => {
      obj.regel = "error_0" + obj.regel.slice(obj.regel.length - 1)
    })

    mistakeData = wrongMistakeData.concat(correctMistakeData)

    const teacherDashboardData = this.getPreparedData(
      classData.classById,
      mistakeData
    ).studentData.slice(0, -1)
    const teacherDashboardOrder = [
      "name",
      "duration",
      "durationFb",
      "totalTime",
      "numberOfWords",
      "correctWordRatio",
      "averageAttempts",
    ]
    const studentsWritingsData =
      this.getConcreteWritings(studentsData).inputData
    const studentsWritingsOrder = [
      "name",
      "word",
      "input",
      "correct",
      "attempt",
      "calendar",
      "clock",
    ]

    this.setState(
      {
        class: {
          id: classId,
          name: className,
          students: studentsData,
        },
        mistakes: mistakeData,
        teacherDashboardData: teacherDashboardData,
        teacherDashboardOrder: teacherDashboardOrder,
        studentsWritingsData: studentsWritingsData,
        studentsWritingsOrder: studentsWritingsOrder,
      },
      () => this.forceUpdate()
    )
  }

  sum = (array) => array.reduce((pv, cv) => pv + cv, 0)

  mode = (array) => {
    if (array.length == 0) return null
    var modeMap = {}
    var maxEl = array[0],
      maxCount = 1
    for (var i = 0; i < array.length; i++) {
      var el = array[i]
      if (modeMap[el] == null) modeMap[el] = 1
      else modeMap[el]++
      if (modeMap[el] > maxCount) {
        maxEl = el
        maxCount = modeMap[el]
      }
    }
    return maxEl
  }
  getConcreteWritings = (students) => {
    console.log("students:", students)
    const studentsData = students
    let preparedData = {
      inputData: [
        {
          name: "",
          word: "",
          attempt: null,
          correct: "",
          calendar: "",
          clock: "",
        },
      ],
    }
    studentsData.forEach((student) => {
      let name = ""
      if (student.name == null || student.name == "") {
        name = "Kein Name gefunden!"
      } else {
        name = student.name
      }
      const sessions = student.inputSessionsByStudent.nodes
      if (sessions.length > 0) {
        sessions.forEach((input) => {
          let calendar = ""
          let clock = ""
          const id = sessions.indexOf(input) + 1
          const word = input.word

          const d = new Date()
          d.setTime(input.startTime)
          //d.setFullYear(input.startTime)
          if (input.startTime) {
            const year = d.getFullYear().toString()
            const month = (d.getMonth() + 1).toString()
            const day = d.getDate().toString()
            let hour = d.getHours()
            let ampm = hour >= 12 ? "pm" : "am"
            hour = hour % 12
            hour = hour ? hour : 12
            hour = hour < 10 ? "0" + hour.toString() : hour.toString()
            const min = ("0" + d.getMinutes().toString()).slice(-2)
            const sec = ("0" + d.getSeconds().toString()).slice(-2)

            calendar = year + "-" + month + "-" + day
            clock = hour + ":" + min + ":" + sec + " " + ampm
          }

          const writtenByStudent = input.input

          const attempt = input.attempt
          const correct = input.correct

          preparedData.inputData.push({
            name: name,
            correct: correct ? "Ja" : "Nein",
            word: word,
            input: writtenByStudent,
            attempt: attempt,
            calendar: calendar,
            clock: clock,
          })
        })
      }
    })
    preparedData.inputData.reverse().pop()
    return preparedData
  }

  getPreparedData = (classObj, mistakes) => {
    console.log("classObj:", classObj)
    console.log("mistakes:", mistakes)
    const rawData = { class: classObj, mistakes: mistakes }
    const className = rawData.class.name
    const studentsData = rawData.class.studentsByClass.nodes
    const mistakeData = rawData.mistakes

    // for summarized class data:
    let numberOfCorrectWordsSum = 0
    let numberOfWordsSum = 0
    let attemptArraySum = []

    let preparedData = {
      className: className,
      studentData: [
        {
          name: ("class " + className).toUpperCase(),
          numberOfWords: 0,
          correctWordRatio: 0.0,
          averageAttempts: 0.0,
          duration: "",
          durationFb: "",
          totalTime: "",
        },
      ],
    }
    studentsData.forEach((student) => {
      // Calculate number of words
      const inputs = student.inputSessionsByStudent.nodes
      const words = inputs.filter((input) => input.word != null)
      const uniqueWords = [...new Set(words.map((obj) => obj.word))]
      const numberOfUniqueWords = uniqueWords.length

      // Calculate correct word ratio
      let numberOfWords = 0

      words.forEach((word) => {
        if (word != null) {
          numberOfWords++
        }
      })

      let learningTime = 0.0
      let feedbackTime = 0.0

      inputs.forEach((input) => {
        learningTime += input.durationMs
        feedbackTime += input.durationMsFb
      })

      const totalTime = learningTime + feedbackTime

      const learningMins = Math.floor(learningTime / 1000 / 60)
      const feedbackMins = Math.floor(feedbackTime / 1000 / 60)
      const totalMins = Math.floor(totalTime / 1000 / 60)

      const learningSecs = Math.floor(learningTime / 1000 - learningMins * 60)
      const feedbackSecs = Math.floor(feedbackTime / 1000 - feedbackMins * 60)
      const totalSecs = Math.floor(totalTime / 1000 - totalMins * 60)

      const StringLearningTime =
        learningMins.toString() + ":" + learningSecs.toString()
      const StringFeedbackTime =
        feedbackMins.toString() + ":" + feedbackSecs.toString()
      const StringTotalTime = totalMins.toString() + ":" + totalSecs.toString()

      const wordsForRatio = inputs.map((input) => input.word)
      const numberOfWordsForRatio = wordsForRatio.length

      numberOfWordsSum += numberOfWordsForRatio
      const correctWords = inputs.filter((input) => input.correct === true)
      const numberOfCorrectWords = correctWords.length
      numberOfCorrectWordsSum += numberOfCorrectWords
      let correctWordRatio = (
        (numberOfCorrectWords / numberOfWordsForRatio) *
        100
      ).toFixed(0)
      if (isNaN(correctWordRatio)) {
        correctWordRatio = 0
      }

      // Calculate average number of attempts
      const attemptArray = correctWords.map((word) => word.attempt)
      attemptArraySum = attemptArraySum.concat(attemptArray)
      let averageAttempts = (sum(attemptArray) / attemptArray.length).toFixed(1)
      if (isNaN(averageAttempts)) {
        averageAttempts = 0
      }

      // First try correct pkw ratio
      const firstTryCorrectWords = words
        .filter((word) => word.correct && word.attempt === 1)
        .map((obj) => obj.word)

      // Calculate main mistake
      const mistakeStudentsData = student.mistakePrognosis
      const wrongWords = words
        .filter((word) => !word.correct)
        .map((obj) => obj.word)
      const filteredWrongWords = [...new Set(wrongWords)]

      // jetzt Maximum berechnen... blablabla

      // mistakeData.forEach((data) => {
      //   if (data.regel)
      // })
      const preparedMistakeData = mistakeData.map((obj) => obj.fehlerkategorie)
      if (mistakeStudentsData == null || numberOfUniqueWords === 0) {
        averageAttempts = null
        correctWordRatio = null
      } else {
      }

      let name = ""
      if (student.name == null || student.name == "") {
        name = "Kein Name gefunden!"
      } else {
        name = student.name
      }
      preparedData.studentData.push({
        name: name,
        numberOfWords: numberOfUniqueWords,
        correctWordRatio: correctWordRatio,
        averageAttempts: averageAttempts,
        duration: StringLearningTime,
        durationFb: StringFeedbackTime,
        totalTime: StringTotalTime,
      })
    })

    // Implement ClassData row
    preparedData = {
      className: preparedData.className,
      studentData: preparedData.studentData.reverse(),
    }
    const classOnly = preparedData.studentData.pop()
    classOnly.numberOfWords = sum(
      preparedData.studentData.map((student) => student.numberOfWords)
    )

    const durationMinsSum = sum(
      preparedData.studentData.map((student) =>
        Number(student.duration.split(":").at(0))
      )
    )
    const durationSecsSum = sum(
      preparedData.studentData.map((student) =>
        Number(student.duration.split(":").at(1))
      )
    )

    const durationAdditionalMins = Math.floor(durationSecsSum / 60)
    const newDurationSecs = durationSecsSum - durationAdditionalMins * 60
    const newDurationMins = durationMinsSum + durationAdditionalMins
    classOnly.duration =
      newDurationMins.toString() + ":" + newDurationSecs.toString()

    const durationFbMinsSum = sum(
      preparedData.studentData.map((student) =>
        Number(student.durationFb.split(":").at(0))
      )
    )
    const durationFbSecsSum = sum(
      preparedData.studentData.map((student) =>
        Number(student.durationFb.split(":").at(1))
      )
    )

    const durationFbAdditionalMins = Math.floor(durationFbSecsSum / 60)
    const newDurationFbSecs = durationFbSecsSum - durationFbAdditionalMins * 60
    const newDurationFbMins = durationFbMinsSum + durationFbAdditionalMins
    classOnly.durationFb =
      newDurationFbMins.toString() + ":" + newDurationFbSecs.toString()

    const totalTimeMinsSum = newDurationMins + newDurationFbMins

    const totalTimeSecsSum = newDurationSecs + newDurationFbSecs

    const totalTimeAdditionalMins = Math.floor(totalTimeSecsSum / 60)
    const newTotalTimeSecs = totalTimeSecsSum - totalTimeAdditionalMins * 60
    const newTotalTimeMins = totalTimeMinsSum + totalTimeAdditionalMins
    classOnly.totalTime =
      newTotalTimeMins.toString() + ":" + newTotalTimeSecs.toString()

    // const currentMainMistakeArray = preparedData.studentData
    //   .map((student) => student.currentMainMistake)
    //   .filter((mistake) => mistake.length > 0)

    // if (currentMainMistakeArray.length > 1) {
    //   classOnly.currentMainMistake = this.mode(currentMainMistakeArray)
    // } else {
    //   classOnly.currentMainMistake = currentMainMistakeArray.at(0)
    // }

    classOnly.correctWordRatio = (
      (numberOfCorrectWordsSum / numberOfWordsSum) *
      100
    ).toFixed(0)
    if (isNaN(classOnly.correctWordRatio)) {
      classOnly.correctWordRatio = 0
    }

    classOnly.averageAttempts = (
      sum(attemptArraySum) / attemptArraySum.length
    ).toFixed(1)
    if (isNaN(classOnly.averageAttempts)) {
      classOnly.averageAttempts = 0
    }

    if (classOnly.numberOfWords === 0) {
      classOnly.averageAttempts = null
      classOnly.correctWordRatio = null
    }

    // Put it all together again!
    preparedData.studentData.push(classOnly)
    preparedData.studentData.forEach((data) => {
      if (data.correctWordRatio) {
        data.correctWordRatio = data.correctWordRatio.toString() + "%"
      }
    })

    return preparedData
  }

  // finishPageLoading = async () => {
  //   jwt.checkPermission()
  //   try {
  //     const visitActivity = new TeacherActivity(
  //       this.state.urlPath,
  //       ALL.PAGE_VISITED,
  //       useTeacherStore.getState().teacher.id
  //     )
  //     await visitActivity.writeToDB()
  //   } catch (e) {
  //     console.error("failed to log page visiting: ", e)
  //   }
  // }

  // logPageLeaving = async (event) => {
  //   try {
  //     const leaveActivity = new TeacherActivity(
  //       this.state.urlPath,
  //       ALL.PAGE_LEAVED,
  //       useTeacherStore.getState().teacher.id
  //     )
  //     await leaveActivity.writeToDB()
  //   } catch (e) {
  //     console.error("failed to log page leaving: ", e)
  //   }
  //   if (event) {
  //     event.returnValue = ""
  //   }
  //   return ""
  // }

  // componentWillMount() {
  //   this.finishPageLoading()
  // }

  componentDidMount() {
    if (isBrowser) {
      // const url = new URLSearchParams(window.location.search)
      // const containsClassId = url.has("classId")
      // if (!containsClassId) {
      //   navigate(`/teacher/dashboard?classId=${c.id}`)
      // }
      // window.addEventListener(
      //   isMobileSafari ? "pagehide" : "beforeunload",
      //   //this.logPageLeaving
      // )
      this.setState(
        {
          window: {
            width: window.innerWidth,
            height: window.innerHeight,
          },
        },
        () => window.addEventListener("resize", this.handleResize)
      )
      this.setData()
    }
  }

  componentWillReceiveProps(nextProps) {}

  shouldComponentUpdate(nextProps, nextState) {}

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    if (isBrowser) {
      // window.removeEventListener(
      //   isMobileSafari ? "pagehide" : "beforeunload",
      //   //this.logPageLeaving
      // )

      //this.logPageLeaving()
      window.addEventListener("resize", this.handleResize)
    }
  }

  render() {
    const windowHeight = this.state.window.height
    const className = this.state.class.name

    // const teacherDashboardData = this.getPreparedData().studentData.slice(0, -1)
    // const studentsWritingsData = this.getConcreteWritings().inputData

    // const teacherDashboardOrder = [
    //   "name",
    //   "duration",
    //   "durationFb",
    //   "totalTime",
    //   "numberOfWords",
    //   "correctWordRatio",
    //   "averageAttempts",
    // ]

    // const studentsWritingsOrder = [
    //   "name",
    //   "word",
    //   "input",
    //   "correct",
    //   "attempt",
    //   "calendar",
    //   "clock",
    // ]

    return (
      <div>
        <Seo title="Class Summary" />
        <Navigation>
          <BackButton as={Link} to="/teacher/profile" alt="Back to profile">
            <ArrowIcon />
          </BackButton>
        </Navigation>
        <Content height={windowHeight}>
          <TeacherDashboardLayout>
            <section className="classData">
              <h1>{"Klasse " + className}</h1>
              {this.state.teacherDashboardData.length !== 0 && (
                <DashboardTabs
                  classId={this.state.class.id}
                  studentsData={this.state.class.students}
                  overviewData={this.state.teacherDashboardData}
                  overviewOrder={this.state.teacherDashboardOrder}
                  writingsData={this.state.studentsWritingsData}
                  writingsOrder={this.state.studentsWritingsOrder}
                />
              )}
            </section>
          </TeacherDashboardLayout>
        </Content>
      </div>
    )
  }
}
// component-end

// PropTypes
TeacherDashboardPage.propTypes = {
  window: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  class: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    students: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        alias: PropTypes.string,
        mistakePrognosis: PropTypes.arrayOf(PropTypes.number),
        inputSessionsByStudent: PropTypes.objectOf(
          PropTypes.shape({
            nodes: PropTypes.arrayOf(
              PropTypes.shape({
                word: PropTypes.string,
                correct: PropTypes.bool,
                attempt: PropTypes.number,
              })
            ),
          })
        ),
      })
    ),
  }).isRequired,

  mistakes: PropTypes.arrayOf(
    PropTypes.shape({
      fehlerkategorie: PropTypes.string,
      regel: PropTypes.string,
      dashboardKategorie: PropTypes.arrayOf(PropTypes.string),
    })
  ),
}

export default TeacherDashboardPage
